import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import LoadingPartial from '../../Loading/LoadingPartial';

import gqlQuery from './gql-query';
import { getTimeString, onError } from '../../../utils';
import { useParams } from 'react-router-dom';

const Messages = () => {
  const { consumerId } = useParams() as { consumerId: string };
  const { loading, data } = useQuery(gqlQuery, {
    onError,
    variables: { consumerId },
  });

  if (loading) {
    return <LoadingPartial />;
  }

  if (!data || !data.consumerMessages || !data.consumerMessages.length) {
    return <div className="na-box" />;
  }

  return (
    <>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>作成日</th>
              <th>分類</th>
              <th>ステータス</th>
              <th>メッセージ</th>
            </tr>
          </thead>
          <tbody>
            {data.consumerMessages.map((m: any, i: number) => {
              return (
                <tr key={i}>
                  <td>
                    <time>{getTimeString(new Date(m.createdAt))}</time>
                  </td>
                  <td className="uppercase">{m.kind}</td>
                  <td>
                    <span className={`tag ${m.dispatched ? '' : 'red'}`}>
                      {m.dispatched ? 'Sent' : 'NOT SENT'}
                    </span>
                  </td>
                  <td className="wrap">{m.message || m.subject || 'N/A'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Messages;
