import React, { useState, useEffect, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../Modal/Modal';

import axios from '../../axios';
import { PUSH_MESSAGE } from '../../redux/ui';
import { onError, validateSMS } from '../../utils';

import styles from './AccountPage.module.scss';
import modalStyles from '../Modal/Modal.module.scss';

type SMSModalProps = {
  isOpen: boolean;
  setIsOpen: Function;
  consumerId: string;
  phone: string;
};

const SMSModal = ({ isOpen, setIsOpen, consumerId, phone }: SMSModalProps) => {
  const dispatch = useDispatch();
  const [sms, setSMS] = useState('');
  const [formError, setFormError] = useState('');

  useEffect(() => {
    setFormError('');
  }, [isOpen]);

  const onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errorMsg = validateSMS(sms);

    if (errorMsg) {
      setFormError(errorMsg);

      return;
    }

    const query = `
      {
        sendSms(consumerId: "${consumerId}", smsText: "${sms}")
      }
    `;

    axios
      .post(`/graphql`, {
        operationName: null,
        query,
        variables: {},
      })
      .then(() => {
        dispatch({
          type: PUSH_MESSAGE,
          message: {
            type: 'success',
            body: 'SMS sent successfully',
          },
        });

        setIsOpen(false);
      })
      .catch(error => {
        onError(error);

        dispatch({
          type: PUSH_MESSAGE,
          message: {
            type: 'error',
            body: 'Something went wrong, please try again',
          },
        });
      });
  };

  return (
    <Modal isOpen={isOpen} data-testid="modal">
      <button
        data-testid="close_btn"
        onClick={() => setIsOpen(false)}
        className={modalStyles['close-button']}
      >
        <img
          src={require('../../assets/icon-close-modal.svg')}
          alt="close"
          width="24"
          height="24"
        />
      </button>
      <h2>SMSを送る</h2>
      <form onSubmit={onSubmit} data-testid="form">
        <fieldset>
          <label>電話番号: {phone}</label>
          <textarea
            rows={3}
            placeholder="SMS本文を入力してください。"
            value={sms}
            onChange={event => setSMS(event.currentTarget.value)}
          />
          <span className={styles['word-count']}>
            {`${sms.length}/`}
            <em>128文字</em>
          </span>
          {formError && <p className={modalStyles.error}>{formError}</p>}
        </fieldset>
        <div className={modalStyles.actions}>
          <input type="submit" className="btn" value="送信" />
        </div>
      </form>
    </Modal>
  );
};

export default SMSModal;
