import React, { useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

import axios from '../../axios';

import styles from './Header.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateUIPairs } from '../../redux/ui';
import { updateUIToBeCachedPairs } from '../../redux/ui-to-be-cached';
import { normalizeSearchKeyword, onError } from '../../utils';

const Header = () => {
  const [inputKeyword, setInputKeyword] = useState('');
  const userId = useSelector((state: any) => state.uiToBeCached.userId);
  const searchKeyword = useSelector((state: any) => state.ui.searchKeyword);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const onLogout = async () => {
    try {
      await axios.delete(`/auth/session2`);

      dispatch(
        updateUIToBeCachedPairs({
          userId: '',
        })
      );
    } catch (err) {
      onError(err);
      throw err;
    }
  };

  const onSearch = (event: any) => {
    const code = event.keyCode ? event.keyCode : event.which;

    if (code === 13) {
      const newSearchKeyword = normalizeSearchKeyword(inputKeyword);

      // Do nothing if the search keyword is the same
      if (newSearchKeyword === searchKeyword) {
        return null;
      }

      if (newSearchKeyword !== inputKeyword) {
        setInputKeyword(newSearchKeyword);
      }
      dispatch(updateUIPairs({ searchKeyword: newSearchKeyword }));

      // Redirect to search page if not already there
      if (!location.pathname.includes('search')) {
        navigate('search/payments');
      }
    }
  };

  return (
    <div className={styles['header-wrapper']}>
      <header>
        <Link to="search/payments" className={styles.logo}>
          &nbsp;
        </Link>
        {location.pathname.startsWith('/login') ? null : (
          <>
            <input
              className={styles['input-search']}
              type="search"
              id="search_submit"
              data-testid="search_submit"
              value={inputKeyword}
              placeholder="ID、IdemiaID、VAN、名前、電話番号またはメールアドレスで検索（最低2字）"
              onChange={event => {
                setInputKeyword(event.currentTarget.value);
              }}
              onKeyDown={onSearch}
            />
            <div>
              <span className={styles.account}>{userId}</span>
              <NavLink
                to="installmentPlanCancel"
                className={styles['btn-auth']}
              >
                Installment Plans Cancel
              </NavLink>
              <button
                className={styles['btn-auth']}
                onClick={onLogout}
                data-testid="signout_btn"
              >
                ログアウト
              </button>
            </div>
          </>
        )}
      </header>
    </div>
  );
};

export default Header;
