import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useUnmountableCustomModalContext } from '../PlusDetail/UnmountableCustomModal';

import { ErrorMessage, LoadingMessage } from '../PlusDetail/ModalMessages';
import {
  createAssessment,
  getAssessments,
  getFraudEventLogs,
  getAssessmentKinds,
} from './gql-query';

import modalStyles from '../../Modal/Modal.module.scss';
import styles from './Fraud.module.scss';

type CreateAssessmentModalProps = {
  assessmentKinds: string[];
};

const useCreateAssessment = (
  consumerId: string,
  assessmentKind: string,
  onCompleted: () => void
) => {
  return useMutation(createAssessment, {
    variables: { consumerId, assessmentKind },
    onCompleted,
    refetchQueries: () => [
      {
        query: getAssessments,
        variables: { consumerId },
      },
      {
        query: getFraudEventLogs,
        variables: { consumerId },
      },
      {
        query: getAssessmentKinds,
        variables: { consumerId },
      },
    ],
  });
};

const CreateAssessmentModal = ({
  assessmentKinds,
}: CreateAssessmentModalProps) => {
  const { onCancel, onConfirm } = useUnmountableCustomModalContext();
  const { consumerId = '' } = useParams() as { consumerId: string };
  const [assessmentKind, setAssessmentKind] = useState('');
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const onCompleted = onConfirm;

  const [mutate, { loading, error }] = useCreateAssessment(
    consumerId,
    assessmentKind,
    onCompleted
  );

  const disabled = loading;

  const onSubmit = () => {
    if (!readyToSubmit) {
      setReadyToSubmit(true);
      return;
    }

    mutate();
  };

  const isAssessmentKind = (text: string) => {
    return assessmentKinds.includes(text);
  };

  return (
    <>
      <button onClick={onCancel} className={modalStyles['close-button']}>
        <img
          src={require('../../../assets/icon-close-modal.svg')}
          alt="close"
          width="24"
          height="24"
        />
      </button>
      <h2>Assessment除外フラグ設定する</h2>
      <LoadingMessage loading={loading}></LoadingMessage>
      <ErrorMessage error={error}></ErrorMessage>
      <form onSubmit={e => e.preventDefault()}>
        {!readyToSubmit && (
          <fieldset>
            <label>どのAssessmentに除外フラグを立てますか？</label>
            <input
              list="options"
              placeholder="Assessmentを選択して下さい"
              onChange={event => {
                setAssessmentKind(event.target.value);
              }}
            />
            <datalist id="options">
              {assessmentKinds.map((kind: string, i: number) => (
                <option key={i} value={kind}></option>
              ))}
            </datalist>
          </fieldset>
        )}

        {readyToSubmit && !error && !loading && (
          <div>
            <p>
              下記の設定で顧客アカウントにAssessment除外フラグを立てますか？
            </p>
            <p className={styles.assessmentKind}>• {assessmentKind}</p>
            <p>
              決済承認を得る際に除外フラグのあるAssessmentは無視されているのでご注意ください。
            </p>
          </div>
        )}

        <div className={`${modalStyles.actions} ${styles['action-group']}`}>
          <button
            disabled={
              !assessmentKind || !isAssessmentKind(assessmentKind) || disabled
            }
            className={`btn ${styles['btn-secondary']}`}
            onClick={onSubmit}
          >
            フラグ
          </button>
          <button
            disabled={disabled}
            className={`btn ${styles['btn-primary']}`}
            onClick={onCancel}
          >
            キャンセル
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateAssessmentModal;
