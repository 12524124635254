import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useUnmountableCustomModalContext } from '../PlusDetail/UnmountableCustomModal';

import { ErrorMessage, LoadingMessage } from '../PlusDetail/ModalMessages';
import { createFraudEventLog, getFraudEventLogs } from './gql-query';

import modalStyles from '../../Modal/Modal.module.scss';
import styles from './Fraud.module.scss';

const useCreateFraudEventLog = (
  consumerId: string,
  message: string,
  onCompleted: () => void
) => {
  return useMutation(createFraudEventLog, {
    variables: { consumerId, message },
    onCompleted,
    refetchQueries: () => [
      {
        query: getFraudEventLogs,
        variables: { consumerId },
      },
    ],
  });
};

const CreateFraudEventLogModal = () => {
  const { onCancel, onConfirm } = useUnmountableCustomModalContext();
  const { consumerId } = useParams() as { consumerId: string };

  const [message, setMessage] = useState('');

  const onCompleted = onConfirm;

  const [mutate, { loading, error }] = useCreateFraudEventLog(
    consumerId,
    message,
    onCompleted
  );

  const onSubmit = () => {
    mutate();
  };

  return (
    <>
      <button onClick={onCancel} className={modalStyles['close-button']}>
        <img
          src={require('../../../assets/icon-close-modal.svg')}
          alt="close"
          width="24"
          height="24"
        />
      </button>
      <h2>新しいログを作成</h2>
      <LoadingMessage loading={loading}></LoadingMessage>
      <ErrorMessage error={error}></ErrorMessage>
      <form onSubmit={e => e.preventDefault()}>
        <fieldset>
          <label>メッセージ</label>
          <textarea
            className={styles['fraud-textarea']}
            rows={3}
            placeholder="ログ内容を入力してください。（改行はできません）"
            value={message}
            onChange={event => setMessage(event.currentTarget.value)}
            data-testid="textarea"
          />
        </fieldset>

        <div className={`${modalStyles.actions} ${styles['action-group']}`}>
          <button
            disabled={loading || !message}
            className={`btn ${styles['btn-secondary']}`}
            onClick={onSubmit}
            data-testid="submit"
          >
            追加
          </button>
          <button
            disabled={loading}
            className={`btn ${styles['btn-primary']}`}
            onClick={onCancel}
          >
            キャンセル
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateFraudEventLogModal;
