import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import configureStore from './createStore';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import './index.scss';

export const store = configureStore({});

const persistor = persistStore(store);

const rootEl = document.getElementById('root');

if (rootEl) {
  if (rootEl.hasChildNodes()) {
    hydrate(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>,
      rootEl
    );
  } else {
    render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>,
      rootEl
    );
  }
}
