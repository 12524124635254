import {
  createStore as reduxCreateStore,
  compose,
  applyMiddleware,
} from 'redux';
import { persistReducer } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import rootReducer from './redux';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['uiToBeCached'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const windowExist = typeof window === 'object';

const createStore = (preloadedState: object) => {
  return reduxCreateStore(
    persistedReducer,
    preloadedState,
    compose(
      // applyMiddleware(thunkMiddleware, loggerMiddleware),
      applyMiddleware(thunkMiddleware),
      windowExist && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : x => x
    )
  );
};

export default createStore;
