import React from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';
import { App } from '../App';
import SearchPage from '../components/SearchPage/SearchPage';
import LoginPage from '../components/LoginPage/LoginPage';
import PaymentPage from '../components/PaymentPage/PaymentPage';
import ConsumerPage from '../components/ConsumerPage/ConsumerPage';
import AccountPage from '../components/AccountPage/AccountPage';
import MerchantPage from '../components/MerchantPage/MerchantPage';
import BillStatementPage from '../components/BillStatementPage/BillStatementPage';
import CombiniPaymentPage from '../components/CombiniPaymentPage/CombiniPaymentPage';
import InstallmentPlanCancelPage from '../components/InstallmentPlanCancelPage/InstallmentPlanCancelPage';
import PaidyPayments from '../components/SearchPage/PaidyPayments/PaidyPayments';
import Consumers from '../components/SearchPage/Consumers/Consumers';
import CombiniPayments from '../components/SearchPage/CombiniPayments/CombiniPayments';

import { DuplicateSettlement } from '../components/SearchPage/DuplicateSettlement/DuplicateSettlement';
import Details from '../components/ConsumerPage/Details/Details';
import PlusDetail from '../components/ConsumerPage/PlusDetail/PlusDetail';
import Fraud from '../components/ConsumerPage/Fraud/Fraud';
import Logs from '../components/ConsumerPage/Logs/Logs';
import Messages from '../components/ConsumerPage/Messages/Messages';
import Payments from '../components/ConsumerPage/Payments/Payments';
import Van from '../components/ConsumerPage/VAN/Van';
import { LimitAndUsage } from '../components/ConsumerPage/LimitAndUsage/LimitAndUsage';
import HoukatsuDetail from '../components/ConsumerPage/Houkatsu/HoukatsuDetail';
import { FRAUD_PERMISSION } from '../utils/hooks/use-fraud-permission';
import FraudPayments from '../components/ConsumerPage/FraudPayments/FraudPayments';
import { withPermissionChecker } from '../components/PermissionChecker/PermissionChecker';
import { Auth } from '../components/PaymentPage/Auth/Auth';
import Captures from '../components/PaymentPage/Captures/Captures';
import Assessments from '../components/PaymentPage/Assessments/Assessments';
import Contract from '../components/PaymentPage/Contract/Contract';
import Refunds from '../components/PaymentPage/Refunds/Refunds';
import Message from '../components/PaymentPage/Messages/Messages';
import FraudFlagLogs from '../components/PaymentPage/FraudFlagLogs/FraudFlagLogs';

const FraudPaymentsWithPermission = withPermissionChecker(FraudPayments);
const FraudFlagLogsWithPermission = withPermissionChecker(FraudFlagLogs);

export const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        loader: async () => redirect('/search/payments'), // default route
      },
      {
        path: 'search',
        element: <SearchPage />,
        children: [
          {
            path: 'payments',
            element: <PaidyPayments />,
          },
          {
            path: 'consumers',
            element: <Consumers />,
          },
          {
            path: 'combini-payments',
            element: <CombiniPayments />,
          },
          {
            path: 'duplicate-settlements',
            element: <DuplicateSettlement />,
          },
        ],
      },
      {
        path: 'consumers/:consumerId',
        element: <ConsumerPage />,
        children: [
          {
            path: 'details',
            element: <Details />,
          },
          {
            path: 'limit_and_usage',
            element: <LimitAndUsage />,
          },
          {
            path: 'payments',
            element: <Payments />,
          },
          {
            path: 'houkatsu',
            element: <HoukatsuDetail />,
          },
          {
            path: 'plus',
            element: <PlusDetail />,
          },
          {
            path: 'fraud',
            element: <Fraud />,
          },
          {
            path: 'fraudpayments',
            element: (
              <FraudPaymentsWithPermission
                permissions={[FRAUD_PERMISSION.PAYMENT_FRAUD_FLAGS_READ]}
              />
            ),
          },
          {
            path: 'logs',
            element: <Logs />,
          },
          {
            path: 'messages',
            element: <Messages />,
          },

          {
            path: 'van',
            element: <Van />,
          },
        ],
      },
      {
        path: 'payments/:paymentId',
        element: <PaymentPage />,
        children: [
          {
            path: 'auth',
            element: <Auth />,
          },
          {
            path: 'captures',
            element: <Captures />,
          },
          {
            path: 'assessments',
            element: <Assessments />,
          },
          {
            path: 'contract',
            element: <Contract />,
          },
          {
            path: 'refunds',
            element: <Refunds />,
          },
          {
            path: 'messages',
            element: <Message />,
          },
          {
            path: 'fraud',
            element: (
              <FraudFlagLogsWithPermission
                permissions={[FRAUD_PERMISSION.PAYMENT_FRAUD_FLAGS_READ]}
              />
            ),
          },
        ],
      },
      {
        path: 'accounts/:consumerId',
        element: <AccountPage />,
      },
      {
        path: 'merchant/:tabName',
        element: <MerchantPage />,
      },
      {
        path: 'consumers/:consumerId/billstatements/:billStatementId',
        element: <BillStatementPage />,
      },
      {
        path: 'combini-payments/:combiniPaymentId',
        element: <CombiniPaymentPage />,
      },
      {
        path: 'installmentPlanCancel',
        element: <InstallmentPlanCancelPage />,
      },
    ],
  },
]);
