import React from 'react';

import styles from './LoginPage.module.scss';

type FailedLoginProps = { message: string };

const FailedLogin = ({ message }: FailedLoginProps) => {
  return (
    <div className={styles['failed-login-message']}>
      <p>{message}</p>
    </div>
  );
};

export default FailedLogin;
