import React from 'react';
import { currencyFormatter } from '../../../utils';

import TableRow from './TableRow';
import DataTable from './DataTable';
import styles from './LimitAndUsage.module.scss';

import {
  nlpMigrationStatuses,
  useMigrationStatusQuery,
  useNlpReportQuery,
} from './gql-query';
import { useOutletContext, useParams } from 'react-router-dom';

const statusMap = {
  [nlpMigrationStatuses.approved]: 'NLP承認',
  [nlpMigrationStatuses.canceled]: 'NLPキャンセル済み',
  [nlpMigrationStatuses.never_started]: 'NLP未申請',
  [nlpMigrationStatuses.awaiting_cic_results]: 'CICデータ取得中',
  [nlpMigrationStatuses.pac_data_required]: 'PACデータ待ち',
  [nlpMigrationStatuses.awaiting_pac_assessment]: 'PAC審査処理中',
  [nlpMigrationStatuses.rejected]: 'NLP否決',
};

type ColumnData = {
  columnTitle: string;
  columnData: string[];
};

export const LimitAndUsage = () => {
  const { consumerId } = useParams() as { consumerId: string };
  const { isNlpApproved } = useOutletContext() as { isNlpApproved: boolean };
  const { data: nlpMigrationStatusData } = useMigrationStatusQuery(consumerId);
  const { data: nlpReportData } = useNlpReportQuery({
    consumerId,
    skip: !isNlpApproved,
  });

  const tableDataOverallLimits: ColumnData[] = nlpReportData
    ? [
        {
          columnTitle: '今月のご利用可能枠',
          columnData: [
            currencyFormatter.format(nlpReportData?.nlpReport.nmdLimit),
          ],
        },
        {
          columnTitle: '乗数',
          columnData: [`${nlpReportData?.nlpReport.multiplier}×`],
        },
        {
          columnTitle: '最大ご利用可能枠',
          columnData: [
            currencyFormatter.format(nlpReportData?.nlpReport.treLimit),
          ],
        },
        {
          columnTitle: 'ご利用総額',
          columnData: [currencyFormatter.format(nlpReportData?.nlpReport.tre)],
        },
        {
          columnTitle: 'ご利用可能額',
          columnData: [
            currencyFormatter.format(nlpReportData?.nlpReport.availableAmount),
          ],
        },
      ]
    : [];

  const tableDataCurrentMonthUsage: ColumnData[] = nlpReportData
    ? [
        {
          columnTitle: '今月のご利用可能額',
          columnData: [
            currencyFormatter.format(
              nlpReportData?.nlpReport.currentMonthlyPower
            ),
          ],
        },
        {
          columnTitle: '翌月のご請求予定額',
          columnData: [
            currencyFormatter.format(nlpReportData?.nlpReport.nextMonthDue),
          ],
        },
        {
          columnTitle: '未確定',
          columnData: [
            currencyFormatter.format(
              nlpReportData?.nlpReport.unconfirmedPayments
            ),
          ],
        },
        {
          columnTitle: 'ご請求金額（確定済み）',
          columnData: [
            currencyFormatter.format(
              nlpReportData?.nlpReport.closedBillsAmount
            ),
          ],
        },
      ]
    : [];

  const computationCycle = nlpReportData
    ? new Date(nlpReportData.nlpReport.computationCycle)
    : new Date();

  const tableDataFutureMonthlyPower: ColumnData[] = nlpReportData
    ? [
        {
          columnTitle: '月',
          columnData: nlpReportData.nlpReport.changeInMonthlyPower.map(mp => {
            const date = new Date(mp.cycle);

            return `${date.toLocaleString('default', {
              month: 'long',
            })} ${date.getFullYear()}`;
          }),
        },
        {
          columnTitle: '翌月のご請求予定額',
          columnData: nlpReportData.nlpReport.changeInMonthlyPower.map(mp =>
            currencyFormatter.format(mp.nextMonthDue)
          ),
        },
        {
          columnTitle: '未確定',
          columnData: nlpReportData.nlpReport.changeInMonthlyPower.map(mp =>
            currencyFormatter.format(
              mp.unconfirmedPaymentsAndUnconfirmedFutureExposures
            )
          ),
        },
        {
          columnTitle: '各月のご利用可能額',
          columnData: nlpReportData.nlpReport.changeInMonthlyPower.map(mp =>
            currencyFormatter.format(mp.predictiveMonthlyPower)
          ),
        },
        {
          columnTitle: 'ご利用可能額の残額',
          columnData: nlpReportData.nlpReport.changeInMonthlyPower.map(mp =>
            currencyFormatter.format(mp.remainingBalanceOfAvailableAmount)
          ),
        },
      ]
    : [];

  const tableDataSyntheticMonthlyPower: ColumnData[] = nlpReportData
    ? [
        {
          columnTitle: '支払い回数',
          columnData: nlpReportData.nlpReport.syntheticMonthlyPower.plus.map(
            item => `${item.name}回あと払い`
          ),
        },
        {
          columnTitle: '3・6・12回あと払い利用時の単月のご利用可能枠',
          columnData: nlpReportData.nlpReport.syntheticMonthlyPower.plus.map(
            item => currencyFormatter.format(item.amount)
          ),
        },
      ]
    : [];

  if (!nlpMigrationStatusData) {
    return null;
  }

  return (
    <>
      <div className={styles['general-info-container']}>
        {nlpMigrationStatusData && (
          <TableRow
            title="NLP移行状況"
            containerClassName={styles['table-row-container']}
          >
            <tr>
              <td
                className={styles['nlp-migration-status']}
                data-status={nlpMigrationStatusData.nlpMigrationStatus.status}
              >
                {statusMap[nlpMigrationStatusData.nlpMigrationStatus.status]}
              </td>
            </tr>
          </TableRow>
        )}
        {/*add NLP migration date when available*/}
        {false && (
          <TableRow
            title="NLP移行日"
            containerClassName={styles['table-row-container']}
          >
            <tr>
              <td className={styles['nlp-migration-date']}>{'2023/04/01'}</td>
            </tr>
          </TableRow>
        )}
      </div>

      {nlpReportData && (
        <>
          <TableRow title="ご利用可能枠/ご利用可能額">
            <tr className={styles['table-container']}>
              <td className={styles['table-container']}>
                <DataTable tableData={tableDataOverallLimits} />
              </td>
            </tr>
          </TableRow>

          <TableRow
            title={
              <>
                今月の利用状況
                <br />(
                {computationCycle.toLocaleString('default', {
                  month: 'long',
                })}{' '}
                {computationCycle.getFullYear()})
              </>
            }
          >
            <tr className={styles['table-container']}>
              <td className={styles['table-container']}>
                <DataTable tableData={tableDataCurrentMonthUsage} />
              </td>
            </tr>
          </TableRow>

          <TableRow title="各月のご利用可能額">
            <tr className={styles['table-container']}>
              <td className={styles['table-container']}>
                <DataTable
                  variant="grey"
                  tableData={tableDataFutureMonthlyPower}
                />
              </td>
            </tr>
          </TableRow>

          <TableRow title={`3・6・12回あと払い利用時\nの単月のご利用可能枠`}>
            <tr className={styles['table-container']}>
              <td className={styles['table-container']}>
                <DataTable
                  variant="grey"
                  tableData={tableDataSyntheticMonthlyPower}
                />
              </td>
            </tr>
          </TableRow>
        </>
      )}
    </>
  );
};
