import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useUnmountableCustomModalContext } from '../PlusDetail/UnmountableCustomModal';

import modalStyles from '../../Modal/Modal.module.scss';
import styles from './Fraud.module.scss';

import {
  deleteAssessment,
  getAssessments,
  getFraudEventLogs,
  getAssessmentKinds,
} from './gql-query';
import { ErrorMessage, LoadingMessage } from '../PlusDetail/ModalMessages';

type DeleteAssessmentModalProps = {
  exclusionId: string;
};

const useDeleteAssessment = (
  consumerId: string,
  exclusionId: string,
  onCompleted: () => void
) => {
  return useMutation(deleteAssessment, {
    variables: { consumerId, exclusionId },
    onCompleted,
    refetchQueries: () => [
      {
        query: getFraudEventLogs,
        variables: { consumerId },
      },
      {
        query: getAssessmentKinds,
        variables: { consumerId },
      },
    ],
    update: (cache: any, res) => {
      if (
        res &&
        res.data &&
        res.data.deactivateAssessmentExclusion.error !== null
      )
        return;

      const variables = { consumerId };

      const { assessmentExclusionsByConsumerId = [] } = cache.readQuery({
        query: getAssessments,
        variables,
      });

      const data = {
        ...assessmentExclusionsByConsumerId,
        assessmentExclusions: assessmentExclusionsByConsumerId.assessmentExclusions.filter(
          (assessment: any) =>
            assessment.id !== res.data.deactivateAssessmentExclusion.id
        ),
      };

      cache.writeQuery({
        query: getAssessments,
        variables,
        data: { assessmentExclusionsByConsumerId: data },
      });
    },
  });
};

const DeleteAssessmentModal = ({ exclusionId }: DeleteAssessmentModalProps) => {
  const { onCancel, onConfirm } = useUnmountableCustomModalContext();
  const { consumerId } = useParams() as { consumerId: string };

  const onCompleted = onConfirm;

  const [mutate, { loading, error }] = useDeleteAssessment(
    consumerId,
    exclusionId,
    onCompleted
  );

  const disabled = loading;

  const onSubmit = () => {
    mutate();
  };

  return (
    <>
      <button onClick={onCancel} className={modalStyles['close-button']}>
        <img
          src={require('../../../assets/icon-close-modal.svg')}
          alt="close"
          width="24"
          height="24"
        />
      </button>
      <h2>Assessment除外フラグを解除する</h2>
      <LoadingMessage loading={loading}></LoadingMessage>
      <ErrorMessage error={error}></ErrorMessage>
      <form onSubmit={e => e.preventDefault()}>
        {!error && !loading && <p>このAssessment除外フラグを解除しますか？</p>}

        <div className={`${modalStyles.actions} ${styles['action-group']}`}>
          <button
            disabled={disabled}
            className={`btn ${styles['btn-secondary']}`}
            onClick={onSubmit}
            data-testid="submit-btn"
          >
            フラグ解除
          </button>
          <button
            disabled={disabled}
            className={`btn ${styles['btn-primary']}`}
            onClick={onCancel}
          >
            キャンセル
          </button>
        </div>
      </form>
    </>
  );
};

export default DeleteAssessmentModal;
