const UPDATE_PAIRS = '@@admin_dashboard/ui-to-be-cached/update_pairs';

const initialState = {};

const uiToBeCached = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAIRS: {
      return {
        ...state,
        ...action.pairs,
      };
    }
    default:
      return state;
  }
};

export const updateUIToBeCachedPairs = pairs => ({
  type: UPDATE_PAIRS,
  pairs,
});

export default uiToBeCached;
