import React, { useState } from 'react';
import cx from 'classnames';

import styles from './LoginPage.module.scss';

type LoginFormProps = {
  loginFailed: string;
  email: string;
  password: string;
  setLoginFailed: Function;
  setEmail: Function;
  setPassword: Function;
  onLogin: any;
};

const LoginForm = ({
  loginFailed,
  email,
  password,
  setLoginFailed,
  setEmail,
  setPassword,
  onLogin,
}: LoginFormProps) => {
  const [formValid, setFormValid] = useState(false);

  return (
    <form onSubmit={onLogin}>
      <fieldset>
        <input
          className={styles.email}
          type="text"
          name="email"
          id="input_email"
          data-testid="input_email"
          placeholder="Email address"
          value={email}
          onChange={event => {
            const value = event.currentTarget.value;

            setLoginFailed('');
            setEmail(value);
            setFormValid(Boolean(value && password));
          }}
        />
        <input
          className={styles.password}
          type="password"
          name="password"
          id="input_password"
          data-testid="input_password"
          placeholder="Password"
          value={password}
          onChange={event => {
            const value = event.currentTarget.value;

            setLoginFailed('');
            setPassword(value);
            setFormValid(Boolean(value && email));
          }}
        />
        {loginFailed && (
          <p className={styles.error}>
            メールアドレスもしくはパスワードが間違っています
          </p>
        )}
      </fieldset>
      <div className={styles.actions}>
        <button
          type="submit"
          data-testid="submit"
          className={cx('btn primary full', formValid ? 'active' : 'disabled')}
        >
          ログイン
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
