import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Pagination from '../Pagination/Pagination';

import {
  getDateString,
  getPrevMonth,
  currencyFormatter,
  pagerize,
} from '../../utils';
import Card from '../Card/Card';

const PER_PAGE = 100;

type BillType = {
  autoDebitSettlement: {
    pullAmount: number;
  };
  billStatement: {
    id: string;
    issueDate: number;
    amount: number;
    unpaidAmount?: number;
    currency: string;
    dueAt: number;
    paidAt?: number;
    status: string;
    autoDebitBy?: number;
  };
};

type BillStatementsProps = {
  billStatements: Array<BillType>;
  consumerId: string;
};

const NAPlaceholder = () => (
  <Card>
    <h3>請求明細</h3>
    <div className="na-box" />
  </Card>
);

const BillStatements = ({
  billStatements,
  consumerId,
}: BillStatementsProps) => {
  const [page, setPage] = useState(0);

  if (!billStatements || !billStatements.length || !consumerId) {
    return <NAPlaceholder />;
  }

  // sorted DESC + filtered out zero-yen bills
  const sortedBillStatements = billStatements
    .filter(b => !!b.billStatement.amount)
    .sort((x, y) => y.billStatement.issueDate - x.billStatement.issueDate);

  if (!sortedBillStatements.length) {
    return <NAPlaceholder />;
  }

  const pageCount = billStatements
    ? Math.ceil(billStatements.length / PER_PAGE)
    : 0;
  const pagedBillStatements = pagerize({
    entries: sortedBillStatements,
    page,
    perPage: PER_PAGE,
  });

  const gotoPage = (p: number) => {
    setPage(p);
  };

  return (
    <Card>
      <h3>請求明細</h3>
      {pageCount > 1 && (
        <Pagination
          pageCount={pageCount}
          page={page}
          gotoPage={gotoPage}
          isTop
        />
      )}
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>利用月</th>
              <th>請求書発行日</th>
              <th className="align-right">請求額</th>
              <th className="align-right">遅延手数料</th>
              <th className="align-right">未払い請求額</th>
              <th>支払期限</th>
              <th>ステータス</th>
              <th>口座振替</th>
              <th>詳細</th>
            </tr>
          </thead>
          <tbody>
            {pagedBillStatements.map((b, i) => (
              <tr key={i}>
                <td>
                  <time>
                    {getPrevMonth(new Date(b.billStatement.issueDate))}
                  </time>
                </td>
                <td>
                  <time>
                    {getDateString(new Date(b.billStatement.issueDate))}
                  </time>
                </td>
                <td className="align-right">
                  {currencyFormatter.format(b.billStatement.amount)}
                </td>
                <td className="align-right">
                  {currencyFormatter.format(b.billStatement.lateFeesAmount)}
                </td>
                <td className="align-right">
                  {currencyFormatter.format(b.billStatement.unpaidAmount)}
                </td>
                <td>
                  <time>{getDateString(new Date(b.billStatement.dueAt))}</time>
                </td>
                <td>
                  <span
                    className={`tag ${
                      b.billStatement.status
                        ? b.billStatement.status.toLowerCase()
                        : ''
                    }`}
                  >
                    {b.billStatement.status}
                  </span>
                </td>
                <td>
                  {b.autoDebitSettlement
                    ? currencyFormatter.format(b.autoDebitSettlement.pullAmount)
                    : 'N/A'}
                </td>
                <td>
                  <Link
                    to={`/consumers/${consumerId}/billstatements/${b.billStatement.id}`}
                    className="btn secondary"
                  >
                    詳細
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {pageCount > 1 && (
        <Pagination pageCount={pageCount} page={page} gotoPage={gotoPage} />
      )}
    </Card>
  );
};

export default BillStatements;
