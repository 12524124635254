import { combineReducers } from 'redux';
import ui from './ui';
import uiToBeCached from './ui-to-be-cached';

const appReducer = combineReducers({
  ui,
  uiToBeCached,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export default rootReducer;
