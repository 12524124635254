const UPDATE_PAIRS = '@@admin_dashboard/ui/update_pairs';

export const PUSH_MESSAGE = '@@admin_dashboard/ui/push_message';
export const POP_MESSAGE = '@@admin_dashboard/ui/pop_message';

let messageId = 0;

const initialState = {
  isLoading: false,
  searchKeyword: '',
  searchType: 'payments',
  // messages: [
  //   { id: 9999, type: 'success', body: 'Loaded successfully.' },
  //   { id: 10000, type: 'error', body: 'Update failed.' },
  //   { id: 10001, type: 'info', body: 'Information notification example.' },
  // ],
  messages: [], // [{ type: 'success', message: 'Loaded successfully.' }]
};

const ui = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAIRS: {
      return {
        ...state,
        ...action.pairs,
      };
    }
    case PUSH_MESSAGE: {
      const messages = state.messages;

      if (messages.length) {
        const last = messages[messages.length - 1];

        if (
          last.body === action.message.body &&
          last.type === action.message.type
        ) {
          messages.pop();
        }
      }

      if (messageId >= Number.MAX_SAFE_INTEGER) {
        messageId = 1;
      } else {
        messageId += 1;
      }

      return {
        ...state,
        messages: [...messages, { ...action.message, id: messageId }],
      };
    }
    case POP_MESSAGE: {
      return {
        ...state,
        messages: state.messages.filter(m => m.id !== action.messageId),
      };
    }
    default:
      return state;
  }
};

export const updateUIPairs = pairs => ({
  type: UPDATE_PAIRS,
  pairs,
});

export const pushMessage = (message: object) => ({
  type: PUSH_MESSAGE,
  message,
});

export const popMessage = (_messageId: number) => ({
  type: POP_MESSAGE,
  messageId: _messageId,
});

export default ui;
