import { useMutation, useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import idx from 'idx';
import React, { useState, useContext, createContext } from 'react';
import { cancelContract, contractsByConsumerId } from './gql-query';

type HoukatsuContextType = {
  consumerId: string;
  data?: any;
  contracts: any[];
  error: any;
  pageCount: number;
  page: number;
  gotoPage: Function;
  loading: boolean;
};

const houkatsuContext = createContext<HoukatsuContextType>({
  consumerId: '',
  data: null,
  contracts: [],
  error: null,
  pageCount: 0,
  page: 1,
  gotoPage: () => null,
  loading: false,
});

export const useHoukatsuContext = () => useContext(houkatsuContext);

export const HoukatsuDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { consumerId } = useParams() as { consumerId: string };
  const { data, loading, error } = useQuery(contractsByConsumerId, {
    variables: { consumerId },
  });

  const threePayContracts = (idx(data, _ => _.threePayContracts) || []).map(
    (c: any) => ({
      type: '3-Pay',
      ...c,
    })
  );

  const sixPayContracts = (idx(data, _ => _.sixPayContracts) || []).map(
    (c: any) => ({
      type: '6-Pay',
      ...c,
    })
  );

  const twelvePayContracts = (idx(data, _ => _.twelvePayContracts) || []).map(
    (c: any) => ({
      type: '12-Pay',
      ...c,
    })
  );

  const appleContracts = (idx(data, _ => _.appleContracts) || []).map(
    (c: any) => ({
      type: 'Apple',
      ...c,
    })
  );

  const allContracts = [
    ...threePayContracts,
    ...sixPayContracts,
    ...twelvePayContracts,
    ...appleContracts,
  ];

  allContracts.sort((a, b) => b.dateConverted - a.dateConverted);

  const noItemPerPage = 10;

  const pageCount = Math.ceil((allContracts.length || 0) / noItemPerPage) || 1;

  const [page, setPage] = useState(0);

  const gotoPage = (i: number) => setPage(i);

  const contracts = allContracts.slice(
    page * noItemPerPage,
    (page + 1) * noItemPerPage
  );

  if (!consumerId) return null;

  return (
    <houkatsuContext.Provider
      value={{
        consumerId,
        pageCount,
        page,
        gotoPage,
        contracts,
        loading,
        error,
      }}
    >
      {children}
    </houkatsuContext.Provider>
  );
};

export const useCancelContract = (
  consumerId: string,
  installmentPlanId: string,
  onCompleted: () => void
) =>
  useMutation(cancelContract, {
    variables: { installmentPlanId },
    onCompleted,
    update: (cache: any, res) => {
      if (res && res.data && res.data.cancelContract !== 'Success') return;

      const variables = { consumerId };

      const {
        threePayContracts = [],
        sixPayContracts = [],
        twelvePayContracts = [],
        appleContracts = [],
      } = cache.readQuery({
        query: contractsByConsumerId,
        variables,
      });

      const data = {
        threePayContracts: [
          ...threePayContracts.map((contract: any) => {
            if (contract.id === installmentPlanId)
              return { ...contract, status: 'canceled' };

            return contract;
          }),
        ],
        sixPayContracts: [
          ...sixPayContracts.map((contract: any) => {
            if (contract.id === installmentPlanId)
              return { ...contract, status: 'canceled' };

            return contract;
          }),
        ],
        twelvePayContracts: [
          ...twelvePayContracts.map((contract: any) => {
            if (contract.id === installmentPlanId)
              return { ...contract, status: 'canceled' };

            return contract;
          }),
        ],
        appleContracts: [
          ...appleContracts.map((contract: any) => {
            if (contract.id === installmentPlanId)
              return { ...contract, status: 'canceled' };

            return contract;
          }),
        ],
      };

      cache.writeQuery({
        query: contractsByConsumerId,
        variables,
        data,
      });
    },
  });
