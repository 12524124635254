import axios from 'axios';
import axiosRetry from 'axios-retry';

import { API_HOST } from './constants';

const instance = axios.create({
  baseURL: API_HOST,
  'Content-Type': 'application/json',
  withCredentials: true,
});

axiosRetry(instance, { retries: 3 });

export default instance;
