import React, { useState, useEffect, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../../Modal/Modal';

import axios from '../../../axios';
import { PUSH_MESSAGE } from '../../../redux/ui';
import { onError, validateAdminlog } from '../../../utils';

import modalStyles from '../../Modal/Modal.module.scss';
import styles from './CreatingAdminLog.module.scss';

type CreatingAdminLogProps = {
  isOpen: boolean;
  setIsOpen: Function;
  consumerId: string;
  setCreatedAdminlog: Function;
};

const CreatingAdminLog = ({
  isOpen,
  setIsOpen,
  consumerId,
  setCreatedAdminlog,
}: CreatingAdminLogProps) => {
  const [log, setLog] = useState('');
  const [logError, setLogError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setLogError('');
  }, [isOpen]);

  const onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errorMsg = validateAdminlog(log);

    if (errorMsg) {
      setLogError(errorMsg);

      return;
    }

    const safeLog = log.replace(/\n/g, '\\n');
    const query = `
      {
        createAdminlog(consumerId: "${consumerId}", message: "${safeLog}", test: false) {
          createdAt
          creator
          message
        }
      }
    `;

    axios
      .post(`/graphql`, {
        operationName: null,
        query,
        variables: {},
      })
      .then((response: any) => {
        dispatch({
          type: PUSH_MESSAGE,
          message: {
            type: 'success',
            body: '新しいログが追加されました',
          },
        });

        setIsOpen(false);

        if (
          response.data &&
          response.data.data &&
          response.data.data.createAdminlog
        ) {
          setCreatedAdminlog(response.data.data.createAdminlog[0]);
        }
      })
      .catch(error => {
        onError(error);
        setLogError('Invalid Message');
      });
  };

  return (
    <Modal className={modalStyles['modal-large']} isOpen={isOpen}>
      <button
        onClick={() => setIsOpen(false)}
        className={modalStyles['close-button']}
      >
        <img
          src={require('../../../assets/icon-close-modal.svg')}
          alt="close"
          width="24"
          height="24"
        />
      </button>
      <h2>新しいログを作成</h2>
      <form onSubmit={onSubmit}>
        <fieldset>
          <label>メッセージ</label>
          <textarea
            className={styles.textarea}
            rows={15}
            placeholder="ログ内容を入力してください。（改行はできません）"
            value={log}
            onChange={event => setLog(event.currentTarget.value)}
          />
          {logError && <p className={modalStyles.error}>{logError}</p>}
        </fieldset>
        <div className={modalStyles.actions}>
          <input type="submit" className="btn" value="追加" />
        </div>
      </form>
    </Modal>
  );
};

export default CreatingAdminLog;
