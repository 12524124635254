import React from 'react';

import { getTimeString, currencyFormatter } from '../../../utils';
import { useOutletContext } from 'react-router-dom';

type CaptureType = {
  id: string;
  amount: number;
  createdAt: number;
  currency: string;
};

type CapturesProps = {
  captures: Array<CaptureType>;
};

const Captures = () => {
  const { captures } = useOutletContext() as CapturesProps;

  if (!captures || !captures.length) {
    return <div className="na-box" />;
  }

  return (
    <>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>キャプチャーID</th>
              <th className="align-right">金額</th>
              <th>作成日</th>
            </tr>
          </thead>
          <tbody>
            {captures.map((c, i) => {
              return (
                <tr key={i}>
                  <td className="bold">{c.id}</td>
                  <td className="align-right">
                    {currencyFormatter.format(c.amount)}
                  </td>
                  <td>
                    <time>{getTimeString(new Date(c.createdAt))}</time>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Captures;
