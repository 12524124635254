import React, { Suspense, useEffect, lazy } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import idx from 'idx';

import LoginPage from './components/LoginPage/LoginPage';
import Header from './components/Header/Header';
import Loading from './components/Loading/Loading';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { updateUIToBeCachedPairs } from './redux/ui-to-be-cached';

import axios from './axios';
import { clientCreator } from './graphql';
import { Outlet } from 'react-router-dom';

const Messages = lazy(() => import('./components/Messages/Messages'));

export const App = () => {
  const client = clientCreator();
  const userId = useSelector(state => state.uiToBeCached.userId);
  const messages = useSelector(state => state.ui.messages);
  const dispatch = useDispatch();

  const getUserEmail = async () => {
    try {
      const response = await axios.get(`/profile/me2`);
      const email = idx(response, _ => _.data.email);
      if (email) {
        dispatch(
          updateUIToBeCachedPairs({
            userId: email,
          })
        );
      }
    } catch (err) {
      console.log({ err });

      dispatch(
        updateUIToBeCachedPairs({
          userId: '',
        })
      );
    }
  };

  useEffect(() => {
    getUserEmail();
  }, []);

  if (!userId) {
    return <LoginPage />;
  }

  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<Loading />}>
        <ErrorBoundary>
          <Header />
          <main>
            <Outlet />
            <Suspense fallback={null}>
              <Messages messages={messages} />
            </Suspense>
          </main>
        </ErrorBoundary>
      </Suspense>
    </ApolloProvider>
  );
};
