import React, { useState, lazy, Suspense } from 'react';
import { useQuery } from '@apollo/react-hooks';
import cx from 'classnames';
import { Link, NavLink, Outlet, useParams } from 'react-router-dom';

import LoadingPartial from '../Loading/LoadingPartial';
import Card from '../Card/Card';

import gqlQuery from './gql-query';
import { getTimeString, onError } from '../../utils';

import styles from './ConsumerPage.module.scss';
import {
  PermissionChecker,
  // withPermissionChecker,
} from '../PermissionChecker/PermissionChecker';
import { FRAUD_PERMISSION } from '../../utils/hooks/use-fraud-permission';

const ClosingAccount = lazy(() => import('./ClosingAccount/ClosingAccount'));
const EditingEmail = lazy(() => import('./EditingEmail/EditingEmail'));
const EditingPhone = lazy(() => import('./EditingPhone/EditingPhone'));

const ConsumerPage = () => {
  const { consumerId } = useParams() as { consumerId: string };
  const { loading, data } = useQuery(gqlQuery, {
    onError,
    variables: { consumerId },
  });

  const [closingAccount, setClosingAccount] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingPhone, setEditingPhone] = useState(false);

  const [updatedEmail, setUpdatedEmail] = useState('');
  const [updatedPhone, setUpdatedPhone] = useState('');
  const [updatedStatus, setUpdatedStatus] = useState();

  if (loading) {
    return <LoadingPartial />;
  }

  if (!data || !data.consumer) {
    return <div className="na-box" />;
  }

  const {
    consumer: {
      dateOfBirth,
      email,
      kanaName,
      kanjiName,
      phone,
      createdAt,
      status,
      closedAt,
      closedBy,
    },
    plusstatus: { appleStatus, ekycStatus, houkatsuStatus },
    plusConsumerData,
    activeFraudFlagsByConsumerId,
    assessmentExclusionsByConsumerId: { assessmentExclusions },
  } = data;

  const liquidId =
    plusConsumerData?.eKycConsumerData?.metadata?.liquidApplicationId || '';

  const latestStatus = updatedStatus || status;
  const isActionsDisabled = latestStatus.toLowerCase() === 'closed';
  const hasFraudFlag = activeFraudFlagsByConsumerId?.length > 0;
  const hasAssessment = assessmentExclusions?.length > 0;

  return (
    <>
      <Card>
        <h3>顧客情報</h3>
        <div className={styles['id-flag-group']}>
          <h4 className={styles.id}>
            顧客ID<span>{consumerId}</span>
          </h4>
          {liquidId && (
            <h4 className={`${styles.id}  ${styles['liquid-id']}`}>
              リキッドID<span>{liquidId}</span>
            </h4>
          )}
          {hasFraudFlag && (
            <h4 className={styles.id}>
              <span className={styles.fraud}>Fraudフラグ</span>
            </h4>
          )}
          {hasAssessment && (
            <h4 className={styles.id}>
              <span className={styles.assessment}>Assessment除外フラグ</span>
            </h4>
          )}
        </div>

        <section className={styles['action-section']}>
          <nav className={styles.options}>
            <button
              className={cx(
                styles.option,
                styles.red,
                isActionsDisabled ? styles.disabled : ''
              )}
              onClick={() => {
                if (!isActionsDisabled) {
                  setClosingAccount(true);
                }
              }}
            >
              アカウントを解約
            </button>
            <button
              className={cx(styles.option)}
              onClick={() => setEditingEmail(true)}
            >
              メールアドレスを変更
            </button>
            <button
              className={cx(styles.option)}
              onClick={() => setEditingPhone(true)}
            >
              携帯電話番号を変更
            </button>
          </nav>

          <Link
            className={cx('btn', styles.account)}
            to={`/accounts/${consumerId}`}
          >
            顧客アカウント
          </Link>
        </section>
        <section className={styles['info-section']}>
          <div>
            <div className={styles.label}>
              {kanaName || 'カナ名がありません'}
            </div>
            <div className={cx(styles.value, styles.name)}>
              <span className={styles.kanji}>{kanjiName}</span>
              <div className="tags">
                <span
                  className={`tag ${
                    latestStatus ? latestStatus.toLowerCase() : ''
                  }`}
                >
                  {latestStatus}
                </span>
                {ekycStatus === 'approved' && (
                  <span className={`tag plus-ekyc`}>PLUS EKYC</span>
                )}
                {houkatsuStatus === 'approved' && (
                  <span className={`tag plus-houkatsu`}>PLUS包括</span>
                )}
                {houkatsuStatus === 'approved_for_nlp' && (
                  <span className={`tag plus-houkatsu`}>NLP</span>
                )}
                <span className={`tag apple`}>{appleStatus}</span>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.label}>生年月日</div>
            <div className={cx(styles.value, styles.dob)}>
              {dateOfBirth || 'Empty'}
            </div>
          </div>
          <div>
            <div className={styles.label}>連絡先</div>
            <div className={cx(styles.value, styles.email)}>
              {updatedEmail || email}
            </div>
            <div className={cx(styles.value, styles.phone)}>
              {updatedPhone || phone}
            </div>
          </div>
          <div>
            <div className={styles.label}>登録日</div>
            <div className={styles.value}>
              {getTimeString(new Date(createdAt))}
            </div>
          </div>
          {closedAt && (
            <div>
              <div className={styles.label}>アカウントの削除</div>
              <div className={styles.value}>
                <div>{getTimeString(new Date(closedAt))}</div>
                {closedBy && <div>By {closedBy}</div>}
              </div>
            </div>
          )}
        </section>
      </Card>
      <Card>
        <nav className="tabs">
          <NavLink to={`/consumers/${consumerId}/details`}>顧客詳細</NavLink>
          <NavLink to={`/consumers/${consumerId}/limit_and_usage`}>
            Limit & Usage
          </NavLink>
          <NavLink to={`/consumers/${consumerId}/payments`}>ペイメント</NavLink>
          {(houkatsuStatus === 'approved' ||
            houkatsuStatus === 'approved_for_nlp') && (
            <NavLink to={`/consumers/${consumerId}/houkatsu`}>包括契約</NavLink>
          )}
          {ekycStatus === 'approved' && (
            <NavLink to={`/consumers/${consumerId}/plus`}>PLUS情報</NavLink>
          )}
          <NavLink to={`/consumers/${consumerId}/fraud`}>Fraud</NavLink>
          <PermissionChecker
            permissions={[FRAUD_PERMISSION.PAYMENT_FRAUD_FLAGS_READ]}
          >
            <NavLink to={`/consumers/${consumerId}/fraudpayments`}>
              Fraudペイメント
            </NavLink>
          </PermissionChecker>
          <NavLink to={`/consumers/${consumerId}/logs`}>ログ</NavLink>
          <NavLink to={`/consumers/${consumerId}/messages`}>
            メッセージ ログ
          </NavLink>
          <NavLink to={`/consumers/${consumerId}/van`}>VAN</NavLink>
        </nav>
        <Outlet
          context={{
            consumer: data.consumer,
            plusData: ekycStatus === 'approved' ? plusConsumerData : null,
            payments: data.payments,
            isNlpApproved: houkatsuStatus === 'approved_for_nlp',
          }}
        />
      </Card>
      <Suspense fallback={<LoadingPartial />}>
        <ClosingAccount
          isOpen={closingAccount}
          setIsOpen={setClosingAccount}
          consumerId={consumerId}
          setUpdatedStatus={setUpdatedStatus}
          kanaName={kanaName}
          kanjiName={kanjiName}
          dateOfBirth={dateOfBirth}
          email={email}
        />
      </Suspense>
      <Suspense fallback={<LoadingPartial />}>
        <EditingEmail
          isOpen={editingEmail}
          setIsOpen={setEditingEmail}
          setUpdatedEmail={setUpdatedEmail}
          consumerId={consumerId}
        />
      </Suspense>
      <Suspense fallback={<LoadingPartial />}>
        <EditingPhone
          isOpen={editingPhone}
          setIsOpen={setEditingPhone}
          setUpdatedPhone={setUpdatedPhone}
          consumerId={consumerId}
        />
      </Suspense>
    </>
  );
};

export default ConsumerPage;
